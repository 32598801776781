import { gql } from "@apollo/client/core";

export const importReleaseClientLinksFileMutation = gql`
    mutation importReleaseClientLinksFile($fileName: String!) {
        importReleaseLinks(fileName: $fileName) {
            message
            successRowsNumber
            uninsertedRows {
                ISRC
                Link
                UPC
                clientName
                message
            }
        }
    }
`;

export const importXmlFileMutation = gql`
    mutation importXmlFile(
        $clientId: Int!,
        $fileName: String!
    ) {
        uploadXMLFIle(
            clientId: $clientId,
            fileName: $fileName
        )
    }
`;

export const createDynamicXmlFileMutation = gql`
    mutation createDynamicXmlFile(
        $fileName: String!,
        $releaseId: Int!,
        $clientId: Int!,
        $releaseDate: String!,
        $territoryId: Int!
    ) {
        createdynamicXML(
            fileName: $fileName,
            releaseId: $releaseId,
            clientId: $clientId,
            releaseDate: $releaseDate,
            territoryId: $territoryId
        )
    }
`;

export const importRevenueReportFileMutation = gql`
    mutation importRevenueReportFile(
        $clientId: Int!,
        $templateId: Int!,
        $fileName: String!
    ) {
        importRevenueReport(
            clientId: $clientId,
            templateId: $templateId,
            fileName: $fileName
        )
    }
`;

export const startResumableUploadFileSessionMutation = gql`
    mutation startResumableUploadFileSession($fileName: String!, $type: FileType,$securedNaming: Boolean) {
        startResumableUploadSession(fileName: $fileName, type: $type,securedNaming: $securedNaming) {
            fileName
            resumableSessionUrl
        }
    }
`;

export const saveUploadedFileMetadataMutation = gql`
    mutation saveUploadedFileMetadata($fileName: String!, $type: FileType) {
        saveUploadedFileMetadata(fileName: $fileName, type: $type) {
            file_id
            file_uri
            id
        }
    }
`;

export const saveUploadedTrackFileMetadataMutation = gql`
    mutation saveUploadedTrackFileMetadata($fileName: String!) {
        saveTrackMetadata(fileName: $fileName) {
            id
            file_uri
            file_id
        }
    }
`;

export const deleteFileMutation = gql`
    mutation deleteFile($id: Int!) {
        deleteFile(fileId: $id) {
            id
        }
    }
`;

export const createImageMutation = gql`
    mutation createImage($data: ImageCreateInput!) {
        createOneImage(data: $data) {
            fileId
            id
            downloadUri
        }
    }
`;
