import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const { batchNames: batchNamesSelectionKey } = selectionsData.keys;

const { xml: xmlFileName } = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
} = inputData.types;

const { batchName: batchNameInputName } = inputData.names;

const {
    batchName: batchNameInputLabel,
    xml: xmlInputLabel,
} = inputData.labels;

const clientXmlFileFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: batchNameInputLabel,
        name: batchNameInputName,
        notApple: true,
        selectionKey: batchNamesSelectionKey,
        type: autoCompleteInputType,

    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: xmlInputLabel,
        name: xmlFileName,
        type: fileInputType,
    },
];

export default clientXmlFileFormData;
