import { gql } from "@apollo/client";

export const getClientsListQuery = gql`
    query getClientsList(
        $page: Int = 0,
        $limit: Int = 100000,
        $where: ClientModelWhereInput
    ) {
        listClients(
            page: $page,
            elementsPerPage: $limit,
            where: $where
        ) {
            clients {
                id
                name
                sftp_host
                sftp_username
                sftp_port
            }
            meta {
                resultCount
                nodeCount
                currentPage
                totalPages
            }
        }
    }
`;

export const getClientQuery = gql`
    query getClient($where: ClientModelWhereUniqueInput!) {
        clientModel(where: $where) {
            name
            id
            pdap
            pdap_name
            sftp_host
            sftp_password
            sftp_port
            sftp_username
            sftp_base_folder
            isEnabled
            isApple
            isSecured
            treeObj
            imageWidth
            imageHeight
            XMLFolderStructure
            imageType {
                id
            }
            distributeBatchName {
                id
            }
            trackType {
                id
            }
            keyFile {
                id
            }
            isUpdated
        }
    }
`;

export const getClientXmlFileQuery = gql`
    query getClientXmlFile($id: Int!) {
        previewXML(clientId: $id)
    }
`;

export const getClientTreeObjQuery = gql`
    query getClientTreeObj($where: ClientModelWhereUniqueInput!) {
        clientModel(where: $where) {
            treeObj
            id
        }
    }
`;

export const getClientRevenueReportsTemplatesListQuery = gql`
    query getClientRevenueReportsTemplatesList($id: Int!) {
        getClientReportTemplates(clientId: $id) {
            id
            clientId
            templateName
            templateObj
        }
    }
`;
