import { Box } from "@mui/material";
import { ClientInfoForm, PageHead } from "components";
import { actionsData, tabsData } from "data";
import { useEditClient } from "hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ClientInfoTab = () => {
    const currentClient = useSelector((state) => state.clientsReducer)?.currentClient;

    const { id } = useParams();

    const { t } = useTranslation();

    const {
        editClient,
        loading,
    } = useEditClient(
        parseInt(id),
        tabsData.entities.clientForm[0]?.key,
    );

    const formValues = {
        currentPassword: currentClient?.sftp_password,
        enabled: currentClient?.isEnabled,
        ftpBaseFolder: currentClient?.sftp_base_folder,
        ftpHost: currentClient?.sftp_host,
        ftpPort: currentClient?.sftp_port,
        ftpUsername: currentClient?.sftp_username,
        hasFtpPassword: currentClient?.sftp_password,
        imageHeight: currentClient?.imageHeight,
        imageType: currentClient?.imageType?.id,
        imageWidth: currentClient?.imageWidth,
        isApple: currentClient?.isApple,
        name: currentClient?.name,
        pdap: currentClient?.pdap,
        pdapName: currentClient?.pdap_name,
        secured: currentClient?.isSecured,
        sshKeyFile: currentClient?.keyFile?.id,
        trackType: currentClient?.trackType?.id,
    };

    return (
        <Box>
            <PageHead title={t("tabs.info")} />
            <ClientInfoForm
                action={actionsData.edit}
                loading={loading}
                values={formValues}
                onSubmitForm={editClient}
            />
        </Box>
    );
};

export default ClientInfoTab;
