const inputData = {
    labels: {
        albumCode: "albumCode",
        appleOrganization: "appleOrganization",
        applePassword: "applePassword",
        appleUsername: "appleUsername",
        artist: "artist",
        artistName: "artistName",
        batchName: "batchName",
        cLine: "cLine",
        client: "client",
        code: "code",
        composers: "composers",
        condition: "condition",
        countries: "countries",
        country: "country",
        createdTime: "createdTime",
        customId: "customId",
        dateRangeFrom: "dateRangeFrom",
        dateRangeTo: "dateRangeTo",
        discNumber: "discNumber",
        document: "document",
        driveLink: "driveLink",
        duration: "duration",
        email: "email",
        enabled: "enabled",
        endDate: "endDate",
        familyName: "familyName",
        field: "field",
        fileExtension: "fileExtension",
        fileName: "fileName",
        fileType: "fileType",
        firstName: "firstName",
        folderName: "folderName",
        from: "from",
        ftpAndSftpSettings: "ftpAndSftpSettings",
        ftpBaseFolder: "ftpBaseFolder",
        ftpHost: "ftpHost",
        ftpPassword: "ftpPassword",
        ftpPort: "ftpPort",
        ftpUsername: "ftpUsername",
        generalSettings: "generalSettings",
        genre: "genre",
        hasVideos: "tracksWithVideos",
        height: "height",
        image: "image",
        imageHeight: "imageHeight",
        imageType: "imageType",
        imageWidth: "imageWidth",
        isApple: "isApple",
        isCompleted: "isCompleted",
        isni: "isni",
        isrc: "isrc",
        iswc: "iswc",
        key: "key",
        label: "label",
        link: "link",
        lyrics: "lyrics",
        master: "master",
        mechanicalPerformance: "mechanicalPerformance",
        mimeType: "mimeType",
        modifiedTime: "modifiedTime",
        name: "name",
        nameAr: "nameAr",
        nameEn: "nameEn",
        nationality: "country",
        neighboring: "neighboring",
        note: "note",
        number: "number",
        otherIsrc: "otherIsrc",
        pLine: "pLine",
        pdap: "pdap",
        pdapName: "pdapName",
        platformName: "platformName",
        primaryArtist: "primaryArtist",
        release: "release",
        releaseClientLinks: "releaseClientLinks",
        releaseDate: "releaseDate",
        releaseName: "releaseName",
        releaseType: "releaseType",
        resourceName: "resourceName",
        revenue: "revenue",
        revenueReport: "revenueReport",
        revenueReportTemplate: "revenueReportTemplate",
        rightType: "rightType",
        role: "role",
        searchLabel: "searchLabel",
        secondaryArtists: "secondaryArtists",
        secured: "secured",
        selectableFileName: "selectableFileName",
        selectableFolderName: "selectableFolderName",
        sshKey: "sshKey",
        startDate: "startDate",
        synchronization: "synchronization",
        templateName: "templateName",
        territory: "territory",
        textFileName: "textFileName",
        textFolderName: "textFolderName",
        to: "to",
        track: "track",
        trackType: "trackType",
        tracks: "tracks",
        type: "type",
        upc: "upc",
        "upc/isrc": "upc/isrc",
        value: "value",
        video: "video",
        videoType: "videoType",
        width: "width",
        writers: "writers",
        xml: "xml",
        xmlDefaultSettings: "xmlDefaultSettings",
        xmlFields: "xmlFields",
        xmlType: "xmlType",
    },
    names: {
        albumCode: "albumCode",
        artist: "artist",
        artistName: "artistName",
        attribute: "attribute",
        batchName: "batchName",
        cLine: "cLine",
        categoryFilter: "categoryFilter",
        client: "client",
        code: "code",
        composers: "composers",
        condition: "condition",
        countries: "countries",
        createdTime: "createdTime",
        customId: "customId",
        dashboardSearch: "dashboardSearch",
        date: "date",
        dateRangeFrom: "dateRangeFrom",
        dateRangeTo: "dateRangeTo",
        discNumber: "discNumber",
        driveLink: "driveLink",
        driveManagementSearch: "driveManagementSearch",
        duration: "duration",
        email: "email",
        enabled: "enabled",
        endDate: "endDate",
        familyName: "familyName",
        field: "field",
        file: "file",
        fileExtension: "fileExtension",
        fileName: "fileName",
        fileType: "fileType",
        filteredLabels: "filteredLabels",
        folderName: "folderName",
        from: "from",
        ftpAndSftpSettings: "ftpAndSftpSettings",
        ftpBaseFolder: "ftpBaseFolder",
        ftpHost: "ftpHost",
        ftpPassword: "ftpPassword",
        ftpPort: "ftpPort",
        ftpUsername: "ftpUsername",
        generalSettings: "generalSettings",
        genre: "genre",
        givenName: "givenName",
        groupsSearch: "groupsSearch",
        hasVideos: "hasVideos",
        height: "height",
        imageHeight: "imageHeight",
        imageType: "imageType",
        imageWidth: "imageWidth",
        isApple: "isApple",
        isCompleted: "isCompleted",
        isni: "isni",
        isrc: "isrc",
        iswc: "iswc",
        key: "key",
        label: "label",
        link: "link",
        master: "master",
        mechanicalPerformance: "mechanicalPerformance",
        mimeType: "mimeType",
        modifiedTime: "modifiedTime",
        name: "name",
        nameAr: "nameAr",
        nameEn: "nameEn",
        nationality: "nationality",
        neighboring: "neighboring",
        note: "note",
        number: "number",
        oldImage: "oldImage",
        oldTrack: "oldTrack",
        otherIsrc: "otherIsrc",
        pLine: "pLine",
        pdap: "pdap",
        pdapName: "pdapName",
        platformName: "platformName",
        primaryArtist: "primaryArtist",
        release: "release",
        releaseDate: "releaseDate",
        releaseName: "releaseName",
        releaseType: "releaseType",
        resourceName: "resourceName",
        revenue: "revenue",
        rightType: "rightType",
        role: "role",
        search: "search",
        searchLabel: "searchLabel",
        secondaryArtists: "secondaryArtists",
        secured: "secured",
        select: "select",
        selectAll: "selectAll",
        selectableFileName: "selectableFileName",
        selectableFolderName: "selectableFolderName",
        startDate: "startDate",
        synchronization: "synchronization",
        templateName: "templateName",
        territory: "territory",
        textFileName: "textFileName",
        textFolderName: "textFolderName",
        textValue: "textValue",
        to: "to",
        track: "track",
        trackType: "trackType",
        tracks: "tracks",
        tracksSearch: "tracksSearch",
        type: "type",
        upc: "upc",
        "upc/isrc": "upc/isrc",
        userPermissions: "userPermissions",
        usersSearch: "usersSearch",
        value: "value",
        video: "video",
        videoType: "videoType",
        width: "width",
        withTime: "withTime",
        writers: "writers",
        xmlDefaultSettings: "xmlDefaultSettings",
        xmlFields: "xmlFields",
        xmlType: "xmlType",
    },
    types: {
        autoComplete: "auto-complete",
        category: "category",
        checkBox: "check-box",
        date: "date",
        dateString: "dateString",
        file: "file",
        integer: "integer",
        multiAutoComplete: "multi-auto-complete",
        number: "number",
        password: "password",
        range: "range",
        selection: "selection",
        switch: "switch",
        text: "text",
        textArea: "text-area",
        time: "time",
        title: "title",
        titleWithTopLine: "title-with-topLine",
    },
};

export default inputData;
