import { gql } from "@apollo/client/core";

export const createClientMutation = gql`
    mutation createClient(
        $name: String!,
        $pdap: String!,
        $pdapName: String!,
        $ftpHost: String!,
        $ftpPassword: String,
        $ftpBaseFolder: String!,
        $ftpPort: Int!,
        $ftpUsername: String!,
        $isSecured: Boolean!,
        $isApple: Boolean!,
        $trackTypeId: Int,
        $imageWidth: Int,
        $imageHeight: Int,
        $imageTypeId: Int,
        $sshKeyFile: Int
    ) {
        createClient(
            name: $name,
            pdap: $pdap,
            pdap_name: $pdapName,
            sftp_host: $ftpHost,
            sftp_password: $ftpPassword,
            sftp_port: $ftpPort,
            sftp_base_folder: $ftpBaseFolder,
            sftp_username: $ftpUsername,
            isSecured: $isSecured,
            isApple: $isApple,
            trackTypeId: $trackTypeId,
            imageWidth: $imageWidth,
            imageHeight: $imageHeight,
            imageTypeId: $imageTypeId,
            keyFileId: $sshKeyFile
        ) {
            id
        }
    }
`;

export const editClientMutation = gql`
    mutation editClient(
        $data: ClientModelUpdateInput!,
        $where: ClientModelWhereUniqueInput!
    ) {
        updateClient(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteClientMutation = gql`
    mutation deleteClient($where: ClientModelWhereUniqueInput!) {
        deleteOneClientModel(where: $where) {
            id
        }
    }
`;

export const enableClientMutation = gql`
    mutation enableClient(
        $data: ClientModelUpdateInput!,
        $where: ClientModelWhereUniqueInput!
    ) {
        updateClient(
            data: $data,
            where: $where
        ) {
            id
            isEnabled
        }
    }
`;

export const editClientXmlFileMutation = gql`
    mutation editClientXmlFile(
        $data: ClientModelUpdateInput!,
        $where: ClientModelWhereUniqueInput!
    ) {
        updateClient(
            data: $data,
            where: $where
        ) {
            id
            treeObj
        }
    }
`;

export const editClientRevenueReportsTemplateMutation = gql`
    mutation editClientRevenueReportsTemplate(
        $data: ClientReportTemplateUpdateInput!,
        $where: ClientReportTemplateWhereUniqueInput!
    ) {
        updateOneClientReportTemplate(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteClientRevenueReportsTemplateMutation = gql`
    mutation deleteClientRevenueReportsTemplate($where: ClientReportTemplateWhereUniqueInput!) {
        deleteOneClientReportTemplate(where: $where) {
            id
        }
    }
`;

export const createClientFoldersStructureFolderMutation = gql`
    mutation createClientFoldersStructureFolder(
        $name: String!,
        $type: String!,
        $parentPath: String!,
        $clientId: Int!,
        $isSelection: Boolean!
    ) {
        createFolder(
            name: $name,
            type: $type,
            parentPath: $parentPath,
            clientId: $clientId,
            isSelection: $isSelection
        ) {
            id
            XMLFolderStructure
        }
    }
`;

export const editClientFoldersStructureFolderMutation = gql`
    mutation editClientFoldersStructureFolder(
        $clientId: Int!,
        $parentPath: String!,
        $name: String!,
        $id: String!,
        $isSelection: Boolean!
    ) {
        renameFolder(
            clientId: $clientId,
            parentPath: $parentPath,
            name: $name,
            id: $id,
            isSelection: $isSelection
        )
    }
`;

export const deleteClientFoldersStructureFolderMutation = gql`
    mutation deleteClientFoldersStructureFolder(
        $clientId: Int!,
        $parentPath: String!,
        $name: String!
    ) {
        deleteFolder(
            clientId: $clientId,
            parentPath: $parentPath,
            name: $name
        )
    }
`;

export const createClientFoldersStructureFilesMutation = gql`
    mutation createClientFoldersStructureFiles(
        $clientId: Int!,
        $parentPath: String!,
        $files: [FileInput!]!
    ) {
        createFiles(
            clientId:$clientId,
            parentPath: $parentPath,
            files: $files
        ) {
            id
            XMLFolderStructure
        }
    }
`;
