import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const { sshKey: sshKeyFileName } = fileData.names;

const {
    imageTypes: imageTypesSelectionKey,
    trackTypes: trackTypesSelectionKey,
} = selectionsData.keys;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
    number: numberInputType,
    password: passwordInputType,
    switch: switchInputType,
    text: textInputType,
    title: titleInputType,
    titleWithTopLine: titleWithTopLineInputType,
} = inputData.types;

const {
    enabled: enabledInputName,
    ftpBaseFolder: ftpBaseFolderInputName,
    ftpHost: ftpHostInputName,
    ftpPassword: ftpPasswordInputName,
    ftpPort: ftpPortInputName,
    ftpUsername: ftpUsernameInputName,
    generalSettings: generalSettingsInputName,
    imageHeight: imageHeightInputName,
    imageType: imageTypeInputName,
    imageWidth: imageWidthInputName,
    isApple: isAppleInputName,
    name: nameInputName,
    pdap: pdapInputName,
    pdapName: pdapNameInputName,
    secured: securedInputName,
    trackType: trackTypeInputName,
    xmlDefaultSettings: xmlDefaultSettingsInputName,
} = inputData.names;

const {
    appleOrganization: appleOrganizationInputLabel,
    applePassword: applePasswordInputLabel,
    appleUsername: appleUsernameInputLabel,
    enabled: enabledInputLabel,
    ftpAndSftpSettings: ftpAndSftpSettingsInputLabel,
    ftpBaseFolder: ftpBaseFolderInputLabel,
    ftpHost: ftpHostInputLabel,
    ftpPassword: ftpPasswordInputLabel,
    ftpPort: ftpPortInputLabel,
    ftpUsername: ftpUsernameInputLabel,
    generalSettings: generalSettingsInputLabel,
    imageHeight: imageHeightInputLabel,
    imageType: imageTypeInputLabel,
    imageWidth: imageWidthInputLabel,
    isApple: isAppleInputLabel,
    name: nameInputLabel,
    pdap: pdapInputLabel,
    pdapName: pdapNameInputLabel,
    secured: securedInputLabel,
    sshKey: sshKeyFileInputLabel,
    trackType: trackTypeInputLabel,
    xmlDefaultSettings: xmlDefaultSettingsInputLabel,
} = inputData.labels;

const clientInfoFormData = [
    {
        col: { xs: 12 },
        label: generalSettingsInputLabel,
        name: generalSettingsInputName,
        type: titleInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: nameInputLabel,
        name: nameInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: pdapInputLabel,
        name: pdapInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: pdapNameInputLabel,
        name: pdapNameInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: enabledInputLabel,
        name: enabledInputName,
        type: switchInputType,
    },
    {
        col: { xs: 12 },
        hasSwitch: true,
        label: ftpAndSftpSettingsInputLabel,
        name: isAppleInputName,
        switchLabel: isAppleInputLabel,
        type: titleWithTopLineInputType,
    },

    {
        appleLabel: appleOrganizationInputLabel,
        col: {
            md: 4,
            xs: 12,
        },
        label: ftpHostInputLabel,
        name: ftpHostInputName,
        type: textInputType,
    },
    {
        appleLabel: appleUsernameInputLabel,
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: ftpUsernameInputLabel,
        name: ftpUsernameInputName,
        type: textInputType,
    },
    {
        appleLabel: applePasswordInputLabel,
        col: {
            md: 4,
            xs: 12,
        },
        isOptional: true,
        label: ftpPasswordInputLabel,
        name: ftpPasswordInputName,
        type: passwordInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: ftpPortInputLabel,
        name: ftpPortInputName,
        notApple: true,
        type: numberInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: ftpBaseFolderInputLabel,
        name: ftpBaseFolderInputName,
        notApple: true,
        type: textInputType,

    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: securedInputLabel,
        name: securedInputName,
        notApple: true,
        type: switchInputType,

    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: sshKeyFileInputLabel,
        name: sshKeyFileName,
        notApple: true,
        type: fileInputType,
    },
    {
        col: { xs: 12 },
        label: xmlDefaultSettingsInputLabel,
        name: xmlDefaultSettingsInputName,
        type: titleWithTopLineInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: imageWidthInputLabel,
        name: imageWidthInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        hasOffset: true,
        label: imageHeightInputLabel,
        name: imageHeightInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: imageTypeInputLabel,
        name: imageTypeInputName,
        selectionKey: imageTypesSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        label: trackTypeInputLabel,
        name: trackTypeInputName,
        selectionKey: trackTypesSelectionKey,
        type: autoCompleteInputType,
    },
];

export default clientInfoFormData;
