import { Grid } from "@mui/material";
import { UploadBox } from "atoms";
import {
    constantsData,
    driveManagementData,
    fileData,
    identifiersData,
    inputData,
    statusTypesData,
    tabsData,
    urlsData,
} from "data";
import Cookies from "js-cookie";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { deleteData } from "services";

const {
    audio: audioFileExtension,
    csv: csvFileExtension,
    document: documentFileExtension,
    image: imageFileExtension,
    sshKey: sshKeyFileExtension,
    video: videoFileExtension,
    xml: xmlFileExtension,
} = fileData.extensions;

const {
    document: documentFileName,
    image: imageFileName,
    lyrics: lyricsFileName,
    releaseClientLinks: releaseClientLinksFileName,
    revenueReport: revenueReportFileName,
    revenueReportTemplate: revenueReportTemplateFileName,
    sshKey: sshKeyFileName,
    track: trackFileName,
    video: videoFileName,
    xml: xmlFileName,
    xmlType: xmlTypeFileName,
} = fileData.names;

export const updateStateHandler = (oldState, newState) => ({
    ...oldState,
    ...newState,
});

export const renderFormControllerHandler = (
    control,
    data,
    renderFormControllerChildrenHandler,
    hasDrawer,
) => data.map(({
    appleLabel,
    col,
    drawerCol,
    hasOffset,
    hasSwitch,
    isAsyncMenu,
    isOptional,
    label,
    name,
    selectionKey,
    switchLabel,
    type,
}) => {
    const gridCol = hasDrawer ? drawerCol : col;

    return (
        <Fragment key={name}>
            <Grid
                {...gridCol}
                key={name}
                item
            >
                <Controller
                    control={control}
                    key={name}
                    name={name}
                    render={({ field }) => renderFormControllerChildrenHandler(
                        field,
                        label,
                        name,
                        type,
                        isOptional,
                        selectionKey,
                        isAsyncMenu,
                        hasSwitch,
                        switchLabel,
                        appleLabel,
                    )}
                />
            </Grid>
            {hasOffset && !hasDrawer && (
                <Grid
                    xs={12}
                    display={{
                        md: "block",
                        xs: "none",
                    }}
                    item
                />
            )}
        </Fragment>
    );
});

export const getSplicedArrayHandler = (array, setArray, value) => {
    const index = array.indexOf(value);

    if (index >= 0) {
        const clonedArray = [...array];

        clonedArray.splice(
            index,
            1,
        );

        setArray(clonedArray);

        return clonedArray;
    }
};

export const convertDateToLocaleStringFormatHandler = (date) => {
    if (Date.parse(date)) {
        const newDate = new Date(date);

        const year = newDate.getFullYear();

        let month = newDate.getMonth() + 1;

        let day = newDate.getDate();

        if (day < 10) day = `0${day}`;

        if (month < 10) month = `0${month}`;

        return `${day}/${month}/${year}`;
    }

    return "";
};

export const convertDateToIsoFormatHandler = (date) => {
    let day = date.getDate().toString();

    let month = (date.getMonth() + 1).toString();

    const year = date.getFullYear().toString();

    if (day.length === 1) day = `0${day}`;

    if (month.length === 1) month = `0${month}`;

    const isoDate = [year, month, day].join("-");

    return isoDate;
};

export const convertTimeToSecondsFormatHandler = (time) => {
    const timeArray = time.split(":");

    const seconds = +timeArray[0] * 60 * 60 + +timeArray[1] * 60 + +timeArray[2];

    return seconds;
};

export const convertHourToDurationFormatHandler = (hour) => {
    let minutes = Math.floor(hour / 60);

    let seconds = hour % 60;

    minutes = minutes < 10 ? `0${minutes}` : minutes;

    seconds = seconds < 10 ? `0${Math.round(seconds)}` : Math.round(seconds);

    return `${minutes}:${seconds}`;
};

export const getNextDayDateHandler = (date) => {
    const newDate = new Date(date);

    newDate.setDate(date.getDate() + 1);

    if (newDate.getMonth() !== date.getMonth()) newDate.setDate(1);

    return new Date(newDate);
};

export const reverseDateHandler = (date) => {
    const splittedDate = date.includes("-") ? date?.split("-") : date?.split("/");

    const reversedDate = splittedDate?.reverse()?.join("-");

    return reversedDate;
};

export const checkFileValidationHandler = (file, name) => {
    switch (name) {
    case imageFileName:
        if (imageFileExtension.find((extension) => file?.type.includes(extension))) return true;
        break;
    case xmlFileName:
    case xmlTypeFileName:
        if (xmlFileExtension.find((extension) => file?.type.includes(extension))) return true;
        break;
    case sshKeyFileName:
        if (sshKeyFileExtension.find((extension) => file?.name.includes(extension))) return true;
        break;
    case trackFileName:
        if (audioFileExtension.find((extension) => file?.type.includes(extension))) return true;
        break;
    case videoFileName:
        if (videoFileExtension.find((extension) => file?.type.includes(extension) || file?.name.includes(extension))) return true;
        break;
    case revenueReportTemplateFileName:
    case revenueReportFileName:
        if (csvFileExtension.find((extension) => file?.type.includes(extension))) return true;
        break;
    case documentFileName:
    case lyricsFileName:
    case releaseClientLinksFileName:
        if (documentFileExtension.find((extension) => file?.type.includes(extension))) return true;
        break;
    default:
        break;
    }
};

export const debounce = (callback, time) => {
    let timeout = null;

    return (value, ...rest) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(
            () => callback(
                value,
                ...rest,
            ),
            time,
        );
    };
};

export const getSubstringHandler = (
    string,
    length = 40,
    withoutEnd,
    endLength = -10,
) => {
    let subString = "";

    if (string?.length > length && !withoutEnd) {
        subString = `${string?.substr(
            0,
            length,
        )}...${string.slice(endLength)}`;
    } else if (string?.length > length && withoutEnd) {
        subString = `${string?.substr(
            0,
            length,
        )}...`;
    } else subString = string;

    return subString;
};

export const renderFilteredTabsHandler = (
    data,
    tabsDataKey,
    entities,
) => {
    let tabs = tabsData.data?.[tabsDataKey];

    entities.forEach(({
        isObject,
        key,
        label,
    }) => {
        if (isObject) {
            if (!(data?.[key] && Object?.keys(data?.[key]).length > 0)) tabs = tabs.filter((tab) => tab.label !== label);
        } else if (!data?.[key]?.length > 0) tabs = tabs.filter((tab) => tab.label !== label);
    });

    return tabs;
};

export const getUniqueRandomId = () => `_${Math.random().toString(36).substr(
    2,
    9,
)}`;

export const getFileSizeHandler = (bytes) => {
    switch (true) {
    case bytes < 1024:
        return `${bytes} bytes`;
    case bytes < 1048576:
        return `${(bytes / 1024).toFixed(2)} KB`;
    case bytes < 1073741824:
        return `${(bytes / 1048576).toFixed(2)} MB`;
    case bytes > 1073741824:
        return `${(bytes / 1073741824).toFixed(2)} GB`;
    default:
        return "_";
    }
};

export const updateUploadFileToastHandler = (
    file,
    progress,
    toastIdsRef,
    status,
    onCancelUpload,
) => {
    const toastId = toastIdsRef.current[file.name];

    const toastType = {
        error: toast.TYPE.ERROR,
        inProgess: toast.TYPE.INFO,
        success: toast.TYPE.SUCCESS,
    };

    if (toastId) {
        if (toast.isActive(toastId)) {
            toast.update(
                toastId,
                {
                    autoClose: status === statusTypesData.success ? 3000 : false,
                    icon: false,
                    render: (
                        <UploadBox
                            file={file}
                            progress={progress}
                            status={status}
                            onCancelUpload={onCancelUpload}
                        />
                    ),
                    type: toastType[status],
                },
            );
        } else {
            const newToastId = toast.info(
                <UploadBox
                    file={file}
                    progress={progress}
                    status={status}
                    onCancelUpload={onCancelUpload}
                />,
                {
                    autoClose: status === statusTypesData.success ? 3000 : false,
                    closeButton: true,
                    icon: false,
                    toastId: file.name,
                    type: toastType[status],
                },
            );

            toastIdsRef.current[file.name] = newToastId; // eslint-disable-line
        }
    } else {
        const newToastId = toast.info(
            <UploadBox
                file={file}
                progress={progress}
                status={status}
                onCancelUpload={onCancelUpload}
            />,
            {
                autoClose: status === statusTypesData.success ? 3000 : false,
                closeButton: true,
                icon: false,
                toastId: file.name,
                type: toastType[status],
            },
        );

        toastIdsRef.current[file.name] = newToastId; // eslint-disable-line
    }
};

export const logoutHandler = async (logout, from) => {
    const { token: tokenConstant } = constantsData;

    if (from) {
        localStorage.setItem( // eslint-disable-line
            "lastVisitedPage",
            from,
        );
    }

    const logoutAction = () => {
        logout({
            logoutParams: { returnTo: window.location.origin }, // eslint-disable-line
        });

        Cookies.remove(tokenConstant);
    };

    await deleteData(
        urlsData.apis.driveManagement.logout,
        null,
        {
            logoutAction,
            token: Cookies.get(tokenConstant),
        },
    );

    logoutAction();
};

export const displayErrorHandler = (
    message,
    dispatch,
    action,
    status,
    logout,
) => {
    dispatch(
        action(
            message,
            statusTypesData.error,
        ),
    );

    if (status === 401) {
        logoutHandler(
            logout,
            window.location.pathname, // eslint-disable-line
        );
    }
};

export const openLinkInNewTabHandler = (url) => {
    window.open( //eslint-disable-line
        url,
        "_blank",
        "noopener,noreferrer",
    );
};

export const copyTextHandler = (text, dispatch, message) => {
    navigator.clipboard.writeText(text). //eslint-disable-line
        then(() => {
            dispatch(openAlert(
                message,
                statusTypesData.success,
            ));
        });
};

export const getDriveManagementUserPermissionsListHandler = (mimeType, isOrganizer) => {
    const finalList = driveManagementData.userPermissions.filter((_, index) => index !== 0 && index !== 1);

    if (!mimeType.includes(constantsData.folder) || !isOrganizer) return finalList.filter((_, index) => index !== 0);

    return finalList;
};

export const clearFormStateAfterSubmissionHandler = (
    response,
    filesData,
    setFilesData,
    setFormReset,
    getValues,
    setValue,
    clearErrors,
) => {
    if (!response?.error) {
        document.querySelectorAll(`.${identifiersData.classNames.clearIndicator}`).forEach(button => button.click()); // eslint-disable-line

        Object.keys(getValues()).forEach((key) => {
            if (key === inputData.names.secondaryArtists) {
                setValue(
                    key,
                    [],
                    { shouldDirty: false },
                );
            } else {
                setValue(
                    key,
                    "",
                    { shouldDirty: false },
                );
            }
        });

        if (filesData) {
            filesData.forEach((file) => {
                setFilesData((prev) => ({
                    ...prev,
                    [file]: null,
                }));
            });
        }

        document.querySelectorAll("input[type='file']").forEach(input => input.value = ""); // eslint-disable-line

        setTimeout(
            () => {
                clearErrors();
            },
            [0],
        );

        if (setFormReset) setFormReset(true);
    }
};

export const deleteFileHandler = (
    setFilesData,
    formMethods,
    name,
) => {
    const fileInput = document.getElementById(`${inputData.names.file}_${identifiersData.ids.input}`); //eslint-disable-line

    if (fileInput) fileInput.value = "";
    else  document.getElementById(name + "_" + identifiersData.ids.input).value = ""; //eslint-disable-line

    setFilesData((prev) => ({
        ...prev,
        [name]: null,
    }));

    formMethods.setValue(
        name,
        null,
    );
};

export const setUploadInputPreviousValueHandler = (
    watch,
    setError,
    setValue,
    filesData,
    fileName,
) => {
    if (!watch(fileName) && filesData[fileName]) {
        let value = "";

        switch (fileName) {
        case imageFileName: value = filesData[fileName]?.imageId;
            break;
        case sshKeyFileName:
        case documentFileName:
        case trackFileName: value = filesData[fileName]?.id;
            break;
        default: value = filesData[fileName];
        }

        setValue(
            fileName,
            value,
        );

        setTimeout(
            () => {
                setError(
                    fileName,
                    null,
                );
            },
            [10],
        );
    }
};
