import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, tabsData } from "data";
import { editClientMutation, getClientQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openAlert, setCurrentClient } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditClient = (id, tab) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const currentClient = useSelector((state) => state.clientsReducer)?.currentClient;

    const [editClientAction, { loading }] = useMutation(
        editClientMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    onCompleted: ({ clientModel }) => dispatch(setCurrentClient(clientModel)),
                    query: getClientQuery,
                    variables: {
                        where: { id },
                    },
                },
            ],
        },
    );

    const editClient = async ({
        batchName,
        enabled,
        ftpBaseFolder,
        ftpHost,
        ftpPassword,
        ftpPort,
        ftpUsername,
        imageHeight,
        imageType,
        imageWidth,
        isApple,
        name,
        pdap,
        pdapName,
        secured,
        sshKeyFile,
        trackType,
    }) => {
        const editClientInfoForm = tab === tabsData.entities.clientForm[0]?.key;

        try {
            await editClientAction({
                variables: {
                    data: {
                        distributeBatchName: {
                            connect: { id: !editClientInfoForm ? batchName : currentClient?.distributeBatchName?.id },
                        },
                        imageHeight: { set: editClientInfoForm ? parseInt(imageHeight) : parseInt(currentClient?.imageHeight) },
                        ...imageType && {
                            imageType: {
                                connect: { id: editClientInfoForm ? imageType : currentClient?.imageType?.id },
                            },
                        },
                        imageWidth: { set: editClientInfoForm ? parseInt(imageWidth) : parseInt(currentClient?.imageWidth) },
                        name: { set: editClientInfoForm ? name : currentClient?.name },
                        pdap: { set: editClientInfoForm ? pdap : currentClient?.pdap },
                        pdap_name: { set: editClientInfoForm ? pdapName : currentClient?.pdap_name }, // eslint-disable-line
                        sftp_base_folder: { set: editClientInfoForm ? ftpBaseFolder||"-" : currentClient?.sftp_base_folder }, // eslint-disable-line
                        sftp_host: { set: editClientInfoForm ? ftpHost : currentClient?.sftp_host }, // eslint-disable-line
                        ...editClientInfoForm && ftpPassword && { sftp_password: { set: ftpPassword }}, // eslint-disable-line
                        sftp_port: { set: editClientInfoForm ? parseInt(ftpPort)||0 : currentClient?.sftp_port }, // eslint-disable-line
                        sftp_username: { set: editClientInfoForm ? ftpUsername : currentClient?.sftp_username }, // eslint-disable-line
                        trackType: {
                            connect: { id: editClientInfoForm ? trackType : currentClient?.trackType?.id },
                        },
                        ...sshKeyFile && editClientInfoForm && {
                            keyFile: {
                                connect: { id: parseInt(sshKeyFile) },
                            },
                        },
                        isApple: { set: editClientInfoForm ? !!isApple : !!currentClient?.isApple },
                        isEnabled: { set: editClientInfoForm ? !!enabled : !!currentClient?.isEnabled },
                        isSecured: { set: editClientInfoForm ? !!secured : !!currentClient?.isSecured },
                    },
                    where: { id },
                },
            });

            dispatch(openAlert(
                t(
                    "messages.editMsgs.success",
                    { entity: t("clients.entity") },
                ),
                statusTypesData.success,
            ));
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editClient,
        loading,
    };
};

export default useEditClient;
